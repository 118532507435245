/* PAGE STYLE */
.imgabout {
  // background-image: url("../../img/standing_man.png");
  // background-repeat: no-repeat;
  // height: 500px;
  width: 250px;
  background-size: contain;

}

.pagecontent,
.pagecontentPr {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  height: 100%;
  z-index: 1000;
  justify-content: space-between;

  @media (max-width: 900px)and (max-height: 400px) {
    flex-wrap: nowrap;
  }

  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
    justify-content: center;


    // flex-direction: column-reverse;
  }

  @media screen and (max-width: 400px) {
    flex-wrap: wrap;
  }
}

.aboutBox {
  width: 76%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 900px)and (max-height: 400px) {
    display: block;
  }

  @media screen and (max-width: 800px) {
    width: 100%
  }

  @media screen and (max-width: 600px) {
    display: inline
  }

}

.pagecontentPart {
  display: flex;
}

/* BOOK BOX */
.bookShelf {
  margin-top: 14rem;
  margin-left: 10rem;
  display: flex;
  position: relative;

  @media (max-width: 900px) {
    width: 100%;
    margin-top: 14rem;
    margin-left: 0rem;
  }

  @media (max-width: 900px)and (max-height: 400px) {
    display: flex;
    justify-content: flex-end;

  }
}

.books {
  z-index: 1500;
  width: 200px;
  height: 394px;
  border-radius: 4px;
  gap: 5px;
  padding: .4em;
  margin-top: 8rem;
  display: flex;
  justify-content: flex-end;
  backdrop-filter: blur(5px);
  background: #113653;
  background: var(--color-marker);
  border-bottom: 3px solid rgba(255, 255, 255, 0.440);
  border-left: 2px rgba(255, 255, 255, 0.545) outset;
  box-shadow: -40px 50px 30px rgba(0, 0, 0, 0.280);
  color: var(--color-text-base);

  @media screen and (max-width: 900px) {
    flex-direction: column;
    width: 100%;
    height: 200px;
  }
}

.books_hover {
  width: 100%;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    width: 100%;
    height: 364px;
  }
}

.onebook {
  height: 100%;
  flex: 1;
  overflow: hidden;
  cursor: pointer;
  border-radius: 2px;
  transition: all .5s;
  border: 1px solid rgba(255, 255, 255, 0.440);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background-color-intense);
  box-shadow: -40px 50px 30px rgba(0, 0, 0, 0.280);


}

.onebook_hover {
  flex: 7;
  width: 10rem;
  align-items: flex-start;

  .bookdiv {
    transform: rotate(0);
    text-align: center;
    color: black;
    background-color: white;
    height: 100%;
    overflow: auto;
  }

  .booktext {
    display: inline;
    text-align: start;
  }

  @media screen and (max-width: 900px) {
    flex: 5;
    width: 100%;
    font-size: small;
    align-items: flex-start;
  }
}

.booktext {
  display: none;

  p {
    margin-top: 2rem;

    span {
      // font-size: 14px;
      font-style: italic;
      color: var(--color-marker);
    }
  }
}

.bookdiv {
  min-width: 14em;
  padding: .5em;
  transform: rotate(-90deg);
  transition: all .5s;
  color: #dbdbe7;
  color: var(--color-text-base);
  letter-spacing: .1em;
  font-size: 14px;

  @media screen and (max-width: 900px) {
    min-width: 10em;
    padding: .3em;
    font-size: small;
    transform: rotate(0deg);
  }
}

.pagecontentPr {
  .aboutBox {
    @media screen and (max-width: 900px) {
      margin-bottom: 3rem;
    }

    .bookShelf {
      margin: 0;

      .books {
        margin: 0;
      }
    }
  }
}

/* TEXT BOX */
.text_container {
  // position: absolute;
  left: 7%;
  top: 10%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 40px;

  @media (max-width: 900px) {
    width: max-content;

    // top: 25%;
    // left: 20%;
  }

  // @media (max-width: 900px)and (max-height: 400px) {
  //   width: max-content;
  //   top: 25%;
  //   left: 20%;
  // }

  // @media screen and (max-width: 800px) {
  //   position: absolute;
  //   left: 0%;
  //   top: 10%;
  //   width: 75%;
  // }

  // @media screen and (max-width: 700px) {
  //   position: absolute;
  //   left: 0%;
  //   top: 10%;
  //   width: 75%;
  // }

  // @media screen and (max-width: 600px) {
  //   position: absolute;
  //   left: 40%;
  //   top: 16%;
  //   width: 75%;
  // }

  // @media screen and (max-width: 500px) {
  //   position: absolute;
  //   left: 30%;
  //   top: 16%;
  //   width: 70%;
  // }

  // @media screen and (max-width: 400px) {
  //   position: absolute;
  //   left: 30%;
  //   top: 16%;
  //   width: 70%;
  // }
}

.text_container_public {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;

  @media screen and (max-width:900px) {
    width: 100%;
  }
}

.text1 {
  font-family: 'Phetsarath';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 77px;
  color: var(--color-text-base);
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 500px) {
    font-size: 40px;
  }

  @media screen and (max-width:900px) {
    line-height: 40px;
  }

  @media (max-width: 900px)and (max-height: 400px) {
    font-size: 30px;
  }
}

.text2 {
  font-family: 'Content';
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 42px;
  color: var(--color-text-base);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 500px) {
    font-size: 20px;
  }

  @media screen and (max-width:900px) {
    line-height: 25px;
  }

  @media (max-width: 900px)and (max-height: 400px) {
    font-size: 20px;
  }

}

.btn {
  z-index: 500;
  border: none;
  background: transparent;
  color: var(--color-marker);
  cursor: pointer;
  text-decoration: none;

  &:hover {
    font-size: 25px;
    cursor: pointer;
    color: var(--color-marker);
  }

}