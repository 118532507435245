.modal-wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: antiquewhite;
    width: 100%;

    .modal-main {
        position: relative;

    }
}

.subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: var(--background-color-base);
    color: var(--color-text-base);
    background-color: var(--background-color-light);

    button {

        border: 3px solid var(--background-color-dark);
        background-color: var(--background-color-intense);
        // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        transition: all 0.2s ease-in-out;
        color: var(--color-text-label);

        &:hover {
            border: 3px solid var(--background-color-intense);
            background-color: var(--background-color-medium);
            color: var(--color-text-base);
        }
    }
}

.colorwrap,
.header {
    background-color: var(--background-color-intense);
    color: var(--color-text-label);

    button {
        background-color: aliceblue;
    }

    .btn-close {
        color: var(--color-text-base);
    }
}

.color {
    background-color: var(--background-color-base);
    color: var(--color-text-base);
}

.colorBody {
    background-color: var(--background-color-light);
    color: var(--color-text-base);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    textarea,
    input {
        margin-top: 5px;
        border-color: var(--color-marker);
        color: var(--color-text-base);
        outline-color: var(--color-marker);
    }

    textarea {
        background-color: var(--background-color-light);
    }
}