.box {
    width: 140px;
    width: 100%;
    /* height: auto; */
    float: left;
    transition: .5s linear;
    position: relative;
    display: block;
    overflow: hidden;
    padding: 8px;
    text-align: center;
    margin: 0 5px;
    background: transparent;
    border-radius: 8px;
    color: #296FD1;    z-index: 500;

  }
  
  .box:before {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    height: 3px;
    width: 100%;
    border-bottom: 3px solid transparent;
    border-left: 3px solid transparent;
    box-sizing: border-box;
    transform: translateX(100%);
  }
  
  .box:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    border-top: 3px solid transparent;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    transform: translateX(-100%);
  }
  
  .box:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  
  .box:hover:before {
    border-color: #262626;
    border-color: #296FD1;
    border-radius: 8px;
    height: 100%;
    transform: translateX(0);
    transition: .3s transform linear, .3s height linear .3s;
  }
  
  .box:hover:after {
    border-color: #262626;
    border-color: #296FD1;    
    border-radius: 8px;
    height: 100%;
    transform: translateX(0);
    transition: .3s transform linear, .3s height linear .5s;
  }
  
  button {
    color: black;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    border: none;    
    background: transparent;
  }