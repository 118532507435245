.login_block {
    width: 350px;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    border-top: 5px solid var(--background-color-intense);
    margin: 0 auto;
    // margin-top: 8rem;
    font-size: large;
    background: var(--background-color-base);
    color: var(--color-text-base);


    .h1login {
        text-align: center;
        color: #000;
        font-size: 18px;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 10px;
    }

    .inputlogin {
        caret-color: black;
        width: 100%;
        height: 42px;
        box-sizing: border-box;
        border-radius: 5px;
        border: 1px solid #ccc;
        margin-bottom: 20px;
        font-size: 14px;
        font-size: large;

        padding: 0 20px 0 50px;
        outline: none;
        color: var(--color-text-base);
        background: var(--background-color-light);
        border: 1px solid var(--color-marker);
    }

    .logbutton {
        width: 100%;
        height: 40px;
        background: var(--background-color-intense);
        box-sizing: border-box;
        border-radius: 5px;
        border: 1px solid #79d1ee;
        color: var(--color-text-base);
        font-weight: bold;
        text-transform: uppercase;
        font-size: 14px;

        outline: none;
        cursor: pointer;

        &:hover {
            background: var(--background-color-dark);
        }

    }
}

.err {
    color: red;
    font-size: large;
}

.success {
    color: rgb(26, 152, 26);
    font-size: large;
}

.lblInput {
    font-size: small;
    margin: 0;
    text-align: left;
    color: rgb(165, 179, 186);
}