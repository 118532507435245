.planWrap {
    .plan {
        display: flex;
        color: var(--color-text-base);

        .planDay {
            p {
                margin: 0;
                font-weight: 700;
            }

            display: flex;
            flex-direction: column;
            margin: 1rem;

            @media screen and (max-width:900px) {
                margin: 0.4rem;
            }
        }

    }

    &::after {
        content: "";
        height: 5px;
        width: 80%;
        background-color: var(--background-color-intense);
        display: block;
        text-align: center;
        margin: auto;
        border-radius: 3px;
    }

    @media screen and (max-width:900px) {
        font-size: 10px;
    }

}


.history {
    color: var(--color-text-base);
    overflow: auto;
    // &::before {
    //     content: "";
    //     height: 5px;
    //     width: 80%;
    //     background-color: var(--background-color-intense);
    //     display: block;
    //     text-align: center;
    //     margin: auto;
    //     border-radius: 3px;
    // }
}