.container_input {
  margin: auto;
  height: 30px;
  width: 250px;

}



/* .container_input:hover .input{
    width: fit-content; 
    opacity: 1;

} */
.input {
  cursor: auto;
  caret-color: black;
  width: 250px;
  padding: 5px 15px 5px 30px;
  border-radius: 9999px;
  border: solid 1px #333;
  transition: all .2s ease-in-out;
  outline: none;
  caret-color: var(--color-text-label);
  border: 1px solid var(--color-marker);
  background: var(--background-color-base);
  color: var(--color-text-base);

  &::placeholder {
    color: var(--color-text-base);
    opacity: 0.8;
    font-style: italic;

  }

  /* opacity: 0.8;  */
}





.btn {
  border: 1px solid transparent;
  height: 100%;
  width: 3rem;
  border-radius: 50%;
  position: absolute;
  right: 1px;
  top: 1px;
  transition: all 0.4s ease-in-out;

  svg {
    color: var(--color-text-base);
    opacity: 0.8;
  }
}

.wrap {
  position: absolute;
  top: 0.9px;
  left: 0.7px;
  border-radius: 50%;
  height: 100%;
  width: 3rem;
  transition: all 0.4s ease-in-out;
  color: var(--color-text-base);
  opacity: 0.8;


}

.btn:hover,
.wrap:hover {
  scale: 1.5;
}