.sideBar-wrap {
    top: 0;
    height: 100vh;
    width: fit-content;
    background-color: var(--background-color-medium);
    display: flex;
    position: sticky;
    align-self: flex-start;
    top: 2rem;

}

.sideBar-narrow {
    height: 100%;
    width: 5rem;
    padding-top: 5rem;
    z-index: 700;
    background-color: var(--background-color-dark);

    button {
        width: 5rem;
        height: 5rem;
        font-size: 20px;
        // margin: 5px 0;
        color: var(--color-text-label);
        margin: 0;

        &:hover {
            background-color: var(--background-color-medium);
        }

        @media screen and (max-height:600px) {
            width: 4rem;
            height: 4rem;
            margin: 0;
        }
    }
}



.sideBar-wide {
    z-index: 600;
    height: 100vh;
    width: 20rem;
    background-color: var(--background-color-medium);
    padding-top: 1.5rem;
    color: var(--color-text-base);
    transition: all 0.4s ease-in-out;

    h3 {
        margin: 0;
    }

    h2 {
        margin: 0;
        text-align: end;
        padding-right: 6px;
        font-size: larger;
        font-family: system-ui;
        position: relative;
        text-align: -webkit-right;
        width: fit-content;
        color: var(--color-text-label);
        background-color: var(--background-color-intense);
        border-radius: 10px;
        padding: 0 5px;
        width: 100%;

        &:after {
            box-shadow: 0 2px 4px #0006, 0 7px 13px -3px #0000004d, inset 0 -3px 0 #0003;
            content: "";
            width: 94%;
            margin: 5px 0 0 15px;
            height: 2px;
            position: absolute;
            bottom: -3px;
            left: -7px;
        }
    }

    .checkbox_wrap {
        display: flex;
        margin: 5px;
    }

    .checkboxL {
        margin-left: 5px;
        font-size: 1.5rem;
        width: 85%;
        margin-bottom: 1rem;
        text-align: justify;
        font-style: italic;
    }

    .checkbox {
        width: 1.5rem;
        height: 1.5rem;
        accent-color: var(--background-color-intense);
    }


}

.link-box {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    margin: 5px 0;
    font-size: 16px;
    text-shadow: 0px 0px 1px rgba(66, 68, 90, 1);
    position: relative;
    color: var(--color-text-base);
    cursor: pointer;

    span {
        color: var(--color-marker);
        margin: 0 0.5rem;
    }

    button {
        color: var(--color-text-base);

        &:hover {
            background-color: var(--background-color-medium);
        }
    }

    &:hover {
        background-color: var(--color-text-inform);
    }
}

.link-box-active {
    background-color: var(--background-color-dark); // #eed8bc;
    background-color: var(--color-text-inform);
    color: var(--color-text-label);
}

.miniMenu {
    background-color: var(--color-text-inform); // #d6d5d4;
    color: var(--color-text-base);
    position: absolute;
    top: 110%;
    right: 2px;
    z-index: 3;
    width: 80%;
    padding: 5px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 5px;

    button {
        color: var(--color-text-base);
        margin: 5px 10px;
        transition: all 0.2s ease-in-out;
        text-align: start;

        &:hover {
            transform: scale(1.2);
        }
    }
}

.titleString {
    background-color: var(--background-color-intense);
    width: 17rem;
}

.btnPlus {
    width: 4rem;
    height: 4rem;
    font-size: 20px;
    line-height: 1;
    text-align: center;
    border-radius: 50%;
    font-weight: 500;
    color: var(--color-text-base);

    &:hover {
        background-color: var(--background-color-dark);
        color: var(--color-text-label);
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
}