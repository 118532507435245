.wrapProgress,
.wrapProgressLG {
    width: 10rem;
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    height: 10px;
}

.wrapProgressLG {
    width: 17rem;
    margin: auto;
    margin-top: 12px;
    height: 15px;
    position: relative;
    margin-bottom: 1rem;




}

.oneStage,
.oneStage0 {
    background-color: chartreuse;
    background-color: var(--color-marker);
    border: 0.5px solid gray;
    width: 10px;
    height: 10px;
    font-size: 10px;
}

.oneStage0 {
    background-color: #e4e4e4;
}

.lg {
    width: 15px;
    height: 15px;

    &:hover {
        scale: 1.05;
        background-color: var(--color-marker);
    }
}

h2 {
    width: 100%;
}

.progressFiltrTitle {
    display: flex;
    width: 100%;

    svg {
        font-size: 14px;
    }

    &:hover {
        background-color: var(--background-color-dark);
        color: var(--color-text-label);
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
}

// .btnClear {
//     width: 3rem;
//     height: 3rem;


//     text-align: center;
//     border-radius: 50%;
//     font-weight: 500;
//     color: var(--color-text-base);
//     font-size: 25px;
//     line-height: 0.9;

//     &:hover {
//         background-color: var(--background-color-dark);
//         color: var(--color-text-label);
//         box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
//     }

// }