@import 'animate.css';



.animation-exit{
    color: white;
    transform: rotateY(0deg);   transform-style: preserve-3d;
  }
  .animation-exit-active{
    transform: rotateY(180deg);
   transition: all 500ms ease-in;
   transform-style: preserve-3d;
  }
  .animation-enter{  color: white;
    transform: rotateY(180deg);  transform-style: preserve-3d;
  }
  .animation-enter-active{
    transform: rotateY(0);
    transition: all 500ms ease-in;
    transform-style: preserve-3d;
  }
  
  .expression-exit{
    transform: scale(1);
  }
  .expression-exit-active{
    transform: scale(0.1);
    transition: all 500ms ease-in;
  }
  .expression-enter{
    transform: scale(0.1);
  }
  .expression-enter-active{
    transform: scale(1);
    transition: all 500ms ease-in;
  }

  .cardFront-enter {
    transform: rotateY(180deg);
  }.cardFront-enter-active {
    transition: all 1000ms ease;
    transform: rotateY(0deg);
  }
  
  .cardFront-exit {
    transform: rotateY(0deg);
  }.cardFront-exit-active {
    transform: rotateY(180deg);
    transition: all 1000ms ease;
  }.cardFront-exit-done {
    transform: rotateY(180deg);
  }
  
  .cardBack-enter {
    transform: rotateY(-180deg);
  }.cardBack-enter-active {
    transform: rotateY(0deg);
    transition: all 1000ms ease;
  }
  
  
  .cardBack-exit {
    transform: rotateY(0deg);
  }.cardBack-exit-active {
    transform: rotateY(-180deg);
    transition: all 1000ms ease;
  }
  
  .card-enter {
    opacity: 0;
    transform: translateX(100%);
  }.card-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 500ms, transform 500ms;
  }

  .card-exit {
    opacity: 1;
    transform: translateX(0%);
  }.card-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 500ms, transform 500ms;
  }

  .card_left-enter {
    opacity: 0;
    transform: translateX(-100%);
  }.card_left-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 500ms, transform 500ms;
  }

.card_left-exit {
    opacity: 1;
    transform: translateX(0%);
  }.card_left-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 500ms, transform 500ms;
  }
  
/* .card_down-enter {
    opacity: 0;
    transform: translateY(-100%);
  }.card_down-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 500ms, transform 500ms;
  }
  
  .card_down-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 500ms, transform 500ms;
  }.card_down-exit {
    opacity: 1;
    transform: translateY(0%);
  } */

  .card_up-enter {
    overflow:  visible ;
    animation: backInUp;
    animation-duration: 1000ms;
  }  .card_up-enter-active {
    overflow:  visible ;   
  } .card_up-enter-done {
    overflow:  visible ;
    animation: backInUp;
    animation-duration: 1000ms;
  }.card_up-exit {
    overflow:  visible ;
    animation: backOutUp;
    animation-duration: 1000ms;
  }.card_up-exit-active {
    overflow:  visible ;
  }.card_up-exit-done {
    overflow:  visible ;
    animation: backOutUp;
    animation-duration: 1000ms;
  }

  .result-appear {
    animation: pulse;
    animation-duration: 1000ms;
  }  .result-appear-done {   
    animation: pulse;
    animation-duration: 1000ms;
  } 

  .game-appear {
    animation: lightSpeedInLeft;
    animation-duration: 1000ms;
  }.game-appear-done {   
    animation: lightSpeedInLeft;
    animation-duration: 1000ms;
  } 


  .count-enter {
    animation: bounceIn;
    animation-duration: 300ms;
  } .count-enter-done {
    animation: bounceIn;
    animation-duration: 300ms;
  }  .count-exit {
    animation: bounceOut;
    animation-duration: 300ms;
  } .count-exit-done {
    animation: bounceOut;
    animation-duration: 300ms;
  }
  

  .countLeft-enter {
    animation: shakeX;
    animation-duration: 300ms;
  } .countLeft-enter-done {
    animation: shakeX;
    animation-duration: 300ms;
  } 


  
  .cardChange-enter {
    animation: fadeInLeft;
    animation-duration: 1000ms;
  }.cardChange-enter-done {
    animation: fadeInleft;
    animation-duration: 1000ms;
  }.cardChange-exit {
    animation: fadeOutRight;
    animation-duration: 1000ms;
  }.cardChange-exit-done {
    animation: fadeOutRight;
    animation-duration: 1000ms;
  }


 

 .sideb-enter {
  animation: slideInDown; 
  animation-duration: 500ms;
}.sideb-enter-done {   
  animation: slideInDown; 
    animation-duration: 500ms;
} 
.sideb-exit {
  animation: slideOutUp; 
  animation-duration: 500ms;
}.sideb-exit-done {   
  animation: slideOutUp; 
    animation-duration: 500ms;
} 

.hintAnim{
  animation: shakeX; 
  animation-duration: 1500ms;
}


.page-appear {
  animation: fadeIn;
  animation-duration: 2000ms;
}.page-appear-done {   
  animation: fadeIn;
  animation-duration: 2000ms;
} 
.sidebar-appear {
  animation: fadeInLeft;
  animation-duration: 500ms;
}.sidebar-appear-done {   
  animation: fadeInLeft;
  animation-duration: 500ms;
} .sidebar-enter {
  animation: fadeInLeft;
  animation-duration: 500ms;
}.sidebar-enter-done {   
  animation: fadeInLeft;
  animation-duration: 500ms;
}.sidebar-exit {
  animation: fadeOutLeft;
  animation-duration: 1500ms;
}.sidebar-exit-activ {   
  animation: fadeOutLeft;
  animation-duration: 1500ms;
} .sidebar-exit-done {   
  animation: fadeOutLeft;
  animation-duration: 1500ms;
} 
.fadeG-appear {
  animation: fadeInDown;
  animation-duration: 600ms;
}


/* Исчезновение блока */
.fadeG-exit {
  animation: fadeOutUp;
  animation-duration: 600ms;
}

.fadeG-exit-active {
  animation: fadeOutUp;
  animation-duration: 600ms;
}