@import 'animate.css';

.main-list {
    display: flex;
    background-color: var(--background-color-medium);
    transition: all 0.4s ease-in-out;

    @media screen and (max-width: 900px) {
        // flex-direction: column
    }
}

.listContainer {
    width: 90%;
    margin: auto;
    background-color: var(--background-color-base);
    color: var(--color-text-base);
    border-radius: 10px;
    margin-top: 2rem;
    transition: all 0.4s ease-in-out;
    min-height: 90vh;
}



.exressions-list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    margin: 5px 10px;
    margin-bottom: 1rem;
    font-size: 14px;

    .filter-summary {
        color: var(--color-marker);
        margin: auto;

        span {
            font-size: 18px;
            font-weight: 500;

        }

        button {
            color: var(--color-marker);
            border: 1px solid var(--color-marker);
            margin: 7px;
            border-radius: 10px;
            transition: all 0.2s ease-in-out;

            &:hover {
                transform: scale(1.2);

            }
        }
    }

    @media screen and (max-width:900px) {
        justify-content: center;
        flex-direction: column;
    }

    .viewSwitcher {
        @media screen and (max-width:900px) {
            scale: 0.8;

            label:nth-child(4) {
                display: none;
            }
        }

        .schema-tgb-active {
            background: var(--background-color-base);
            color: var(--color-marker);
        }

        .schema-tgb {
            background: var(--background-color-base);
            color: var(--color-text-base);
        }

        // label {
        //     background: var(--background-color-base);
        //     color: var(--color-text-base);
        // }

    }
}

.modal-wrap {

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000087;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1000;

    .modal-info {
        background-color: var(--background-color-light);
        margin-top: 12rem;
        padding: 10px;
        border-radius: 30px;
        font-size: initial;

        @media screen and (max-width:900px) {
            margin-top: 2rem;
        }

        .history-box {

            overflow: auto;
            height: 17rem;
        }
    }

    .modal-box {
        margin-top: 12rem;
        position: relative;
        width: 700px;
        max-height: 100vh;
        height: fit-content;
        border-radius: 30px;
        // padding: 10px;
        border: 2px solid var(--color-marker);
        background-color: var(--background-color-light);

        @media screen and (max-width:900px) {
            width: 97vw;
            // margin-top: 1rem;
        }

        .top-edit-box {
            position: relative;
            border: 1px solid transparent;
            width: 100%;
            padding: 0 10px;
            border-radius: 2rem 2rem 0 0;
            text-align: start;
            background-color: black;
            background-color: var(--background-color-light);
            background-color: var(--color-marker);
            min-height: 4rem;
            font-size: 20px;
            height: 4rem;
            cursor: move;
            color: var(--background-color-base);
            text-align: center;

            span {
                position: absolute;
                right: 40%;
                color: var(--color-text-inform);
                font-style: italic;

                @media screen and (max-width:900px) {
                    right: 25%;
                }
            }


            .edit-close-btn {
                border-radius: 50%;
                font-size: 16px;
                height: 3rem;
                width: 3rem;
                position: absolute;
                right: 10px;
                top: 0rem;
                transition: all 0.2s ease-in-out;
                color: var(--background-color-base);

                &:hover {
                    // box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
                    // transform: translateY(-10px);
                    transform: scale(1.2);
                    background: var(--background-color-intense);
                    border: 1px solid var(--color-marker);
                }
            }

            .redX {
                /* position: relative; */
                /* text-decoration: line-through; */
                color: red;

                &:after {
                    color: red;
                    content: "X";
                    position: absolute;
                    left: 20px;
                    font-size: 20px;
                    top: 10px;
                    rotate: 118deg;

                }
            }

            .popupBtn {
                margin-bottom: 1rem;
                text-align: center;
                font-weight: 500;
                display: flex;
                align-items: center;
                width: fit-content;
                font-size: 1.4rem;
                position: absolute;
                border: 1px solid #0b3e86;
                color: white;
                background-color: #3390FF;
                opacity: 0.8;
                height: 100%;
                top: -1px;
                left: -2px;
                border-radius: 20px 0 5px 0;
            }
        }



        .edit-save-btn {
            font-weight: 600;
            // margin: 1rem auto;
            font-size: 14px;
            width: 100%;
            height: 4rem;
            padding: 0 10px;
            position: unset;
            margin-top: 1rem;
            background-color: var(--color-marker);
            color: var(--background-color-base);
            border: 1px solid var(--color-marker);
            border-radius: 0 0 15px 15px;

            &:hover {
                color: var(--color-marker);
                background-color: var(--background-color-light);
            }
        }
    }
}

.cardsContainer,
.rowsContainer {
    width: 90%;
    margin: auto;
    margin-top: 2rem;
    background-color: var(--background-color-base);
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width:900px) {
        margin-left: auto;

    }
}

.expression-body {
    position: relative;
    padding-top: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 2rem;
    margin-left: 0;
    overflow: hidden;
    background-color: var(--background-color-light);
    border-radius: 0 0 30px 30px;
    border: 3px solid (--background-color-intense);

    span {

        color: var(--color-text-inform);
        font-style: italic;
        text-align: center;
        width: 100%;
        display: block;

        @media screen and (max-width:900px) {}
    }

    .phrase-box,
    .phrase-box-sm {
        padding: 10px;
        border: 1px solid transparent;
        background-color: var(--background-color-light);
        padding-top: 1rem;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        min-height: 50px;
        height: auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 0;

        textarea {
            background-color: var(--background-color-medium);
            border-radius: 0;
            width: 100%;
            text-align: justify;
            min-height: 15rem;
            max-height: fit-content;
            caret-color: var(--color-text-base);
            outline: 0px none transparent;
            color: var(--color-text-base);
            border: none;
            // margin-bottom: 2rem;
            overflow-y: auto;
            border: 1px solid var(--color-marker);
            word-wrap: break-word; // height: fit-content;
            margin: 0;

            &::placeholder {
                font-style: italic;
            }
        }

        input {
            width: 100%;
            background-color: var(--background-color-light);
            border-radius: 0 0 15px 15px;
            text-align: justify;
            caret-color: var(--color-text-base);
            outline: 0px none transparent;
            color: var(--color-text-base);
            border: 1px solid var(--color-marker);
            border-top: none;
            font-weight: 200;
            margin: 0;
            display: inline-block;
            position: relative;

            &::placeholder {
                font-style: italic;
            }
        }
    }


    .phrase-box-sm {
        padding: 0;

        textarea {
            min-height: 10rem;
            height: fit-content;
        }

        input {
            border-radius: 0 0 28px 28px
        }

    }


    .expressionStr,
    .expressionStrEmpty {
        position: relative;
        // margin: 1rem;
        text-align: center;
        border-radius: 15px 15px 0 0;
        padding: 0 10px;
        width: 100%;

        .popupBtn {
            position: absolute;
            border: 1px solid var(--color-marker);
            box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
            display: block;
            border-radius: 15px 15px 15px 0;
            left: 0;
            top: 0;
            font-size: 12px;
            padding: 0 2px;
            padding-right: 5px;
            width: fit-content;
            text-wrap: nowrap;
            height: 100%;
            color: white;
            background-color: #3390FF;

            svg {
                position: absolute;
                left: 6px;
                top: 1px;
                text-align: left;
                margin-left: 0;
                margin-right: auto;
                width: 100%;
            }

            &:hover {
                transform: translateY(0);
                transform: translateX(0);

                svg {
                    animation: arr 1s infinite;

                    @keyframes arr {
                        0% {
                            transform: translateX(0);
                        }

                        100% {
                            transform: translateX(40%);
                        }
                    }
                }
            }


        }
    }

    .expressionStr {
        font-weight: 600;
        color: var(--color-text-label);
        background: var(--background-color-intense);
        border: 1px solid var(--color-marker);

        .buttonClear {
            display: none;
            position: absolute;
            line-height: 1;
            border-radius: 15px 15px 0 15px;
            right: 1px;
            padding-bottom: 5px;
            top: 0px;
            left: unset;
            height: 100%;
            width: 4rem;
            color: var(--color-text-label);

            &:hover {
                transform: none;
                color: var(--color-text-base);
                background-color: var(--background-color-medium);
            }
        }

        &:hover .buttonClear {
            display: block;


        }

        // &::after {
        //     content: "";
        //     height: 5px;
        //     width: 80%;
        //     background-color: var(--background-color-intense);
        //     display: block;
        //     text-align: center;
        //     margin: auto;
        //     border-radius: 3px;
        // }
    }

    .expressionStrEmpty {
        font-weight: 600;
        color: var(--color-text-inform);
        background: var(--background-color-light);
        border: 1px solid var(--color-marker);
        text-align: end;
        font-style: italic;
    }
}



.oneItemRow0 {
    position: relative;
    font-size: 2rem;
    margin: 1rem;
    border-radius: 10px;
    color: var(--color-text-base);
    background-color: var(--background-color-light);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    transition: all 0.4s ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 95%;

    &:hover {
        transform: scale(1.05);
        z-index: 600;
        background-color: var(--background-color-medium);
    }

    .done {
        color: var(--color-marker);
        position: absolute;
        right: 10px;
        top: 17px;
        font-size: 3rem;

    }

    .atr {
        position: relative;
        align-items: center;

        .label_wrap {
            width: 10rem;
            margin-top: 0.5rem;

            text-align: left;

            button {
                border: var(--color-marker);
                background-color: transparent;
            }

        }

        .progress {
            margin: 15px 0;
            position: relative;
            cursor: pointer;

            span {
                position: absolute;
                top: -16px;
                left: 103px;
                color: var(--color-marker);

                @media screen and (max-width:900px) {
                    left: 80px;
                }
            }

            &:hover {
                &::after {
                    content: "check your progress";
                    position: absolute;
                    font-size: 10px;
                    width: 100%;
                    left: 0;
                    top: 10px;
                    margin: 0;

                    @media screen and (max-width:900px) {
                        top: 30px;
                    }
                }
            }

            @media screen and (max-width:900px) {
                margin: 0;

            }
        }

    }


    .rowPhrase {
        width: 80%;
        text-align: start;
        padding-left: 12px;
        position: relative;
    }

    .exp-row-btns {
        width: 5rem;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        span {
            color: var(--color-text-inform);

        }

        button {
            position: relative;
            height: 3rem;
            width: 3rem;
            transition: all 0.2s ease-in-out;
            border-radius: 50%;
            text-align: center;

            &:hover {
                // box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
                // transform: translateY(-5px);
                // background-color: var(--background-color-light);
                // background-color: var(--background-color-intense);
                background: var(--background-color-intense);
                border: 1px solid var(--color-marker);
                color: var(--color-marker);
                box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
            }
        }
    }

    @media screen and (max-width:900px) {
        flex-direction: column-reverse;
        padding-bottom: 10px;

        .exp-row-btns {
            display: flex;
            flex-direction: row-reverse;
            // justify-content: flex-start;
            align-content: center;
            width: 100%;

            span {
                padding-left: 10px;
            }
        }

        .atr {
            display: flex;
            justify-content: space-between;
            padding-right: 10px;
            background-color: var(--background-color-pale);
            ;
        }

        .rowPhrase {
            width: 100%;
            padding-left: 5px;
        }
    }
}

.oneItemRow2 {
    position: relative;
    font-size: 2rem;
    // margin: 1rem;
    border-radius: 0px;
    color: var(--color-text-base);
    background-color: var(--background-color-light);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    transition: all 0.4s ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &:hover {
        transform: scale(1.05);
        z-index: 600;
        border-radius: 5px;
        background-color: var(--background-color-medium);
    }

    .done {
        color: var(--color-marker);
        position: absolute;
        right: 10px;
        top: 17px;
        font-size: 3rem;

    }

    .atr {
        position: relative;
        align-items: center;
        display: flex;
        justify-content: space-between;

        .label_wrap {
            width: 10rem;

            button {
                border: var(--color-marker);
                background-color: transparent;
            }

        }

        .progress {
            position: relative;
            cursor: pointer;
            margin-left: 2rem;

            span {
                position: absolute;
                top: -7px;
                right: 0;
                // color: red;
                color: var(--color-marker);
                font-size: 17px;

                @media screen and (max-width:900px) {
                    left: 80px;

                }
            }

            &:hover {
                &::after {
                    content: "check your progress";
                    position: absolute;
                    font-size: 10px;
                    width: 100%;
                    left: 0;
                    top: 25px;
                    margin: 0;

                    @media screen and (max-width:900px) {
                        top: 0px;
                    }
                }
            }

            @media screen and (max-width:900px) {
                margin: 0;

            }
        }

    }


    .rowPhrase {
        width: 80%;
        text-align: start;
        padding-left: 10px;
        position: relative;
    }

    .exp-row-btns {
        width: 7rem;
        font-size: 14px;
        display: flex;
        flex-direction: row-reverse;
        // flex-direction: column;
        align-items: center;
        justify-content: space-between;

        span {
            color: var(--color-text-inform);

        }

        button {
            position: relative;
            height: 3rem;
            width: 3rem;
            transition: all 0.2s ease-in-out;
            border-radius: 50%;
            text-align: center;
            padding: 0;

            &:hover {
                // box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
                // transform: translateY(-5px);
                // background-color: var(--background-color-light);
                // background-color: var(--background-color-intense);

                // transform: scale(1.2);
                background: var(--background-color-intense);
                border: 1px solid var(--color-marker);
                color: var(--color-marker);
                box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
            }
        }
    }

    @media screen and (max-width:900px) {
        flex-direction: column-reverse;
        padding-bottom: 10px;

        .exp-row-btns {
            display: flex;
            flex-direction: row-reverse;
            align-content: center;
            width: 100%;
            font-size: 12px;
            background-color: var(--background-color-pale);

            button {
                height: 2.2rem;
                width: 2.2rem;
                padding: 0;
            }

            span {
                padding-left: 10px;
            }
        }

        .atr {
            display: flex;
            justify-content: space-between;
            padding-right: 10px;
            position: absolute;
            top: -7px;
            width: 25rem;
            scale: (0.7);

            align-items: flex-start;

            .progress {
                height: 15px;
            }
        }

        .rowPhrase {
            width: 100%;
            padding-left: 5px;
            font-size: 12px;
        }
    }
}

.oneItemCard {
    width: 360px;
    position: relative;
    font-size: 2rem;
    margin: 1rem;
    border-radius: 10px;
    background-color: var(--background-color-light);
    color: var(--color-text-base);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    transition: all 0.4s ease-in-out;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: stretch;
    padding-bottom: 10px;
    text-align: left;

    &:hover {
        transform: scale(1.05);
        z-index: 600;
        background-color: var(--background-color-medium);
    }

    .done {
        color: var(--color-marker);
        position: absolute;
        right: 170px;
        top: -13px;
        font-size: 3rem;

    }

    .atr {

        display: flex;
        justify-content: space-between;
        padding-right: 10px;
        background-color: var(--background-color-pale);

        .label_wrap {
            width: 10rem;
            display: flex;
            align-items: center;

            button {
                border: var(--color-marker);
                background-color: transparent;
            }

            &:hover {
                border: var(--color-marker);


            }


            // }
        }

        .progress {
            // margin: 15px 0;
            position: relative;
            cursor: pointer;

            span {
                position: absolute;
                top: 0px;
                left: -25px;
                color: var(--color-marker);
            }

            &:hover {
                &::after {
                    content: "check your progress";
                    position: absolute;
                    font-size: 10px;
                    width: 100%;
                    left: 0;
                    top: 0;
                    margin: 0;
                }
            }
        }
    }

    .rowPhrase {

        text-align: start;
        width: 100%;
        padding: 0 10px;
        position: relative;
    }

    .exp-row-btns {

        font-size: 14px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-content: center;
        width: 100%;

        span {
            color: var(--color-text-inform);
            padding-left: 5px;
        }

        button {
            position: relative;
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
            text-align: center;

            &:hover {
                // transform: translateY(-5px);
                box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
                // background-color: var(--background-color-light);
                background: var(--background-color-intense);
                color: var(--background-color-base);
                border: 1px solid var(--color-marker);
                padding: 0;
            }
        }
    }
}

.applyBtn {
    position: sticky;
    margin-top: 3rem;
    top: 1rem;
    z-index: 1500;
    // background-color: var(--color-text-inform);

    span {
        position: absolute;
        color: var(--color-marker);
        margin: auto;
        top: -3rem;
        font-size: 18px;
        font-weight: 500;
        background-color: var(--background-color-base);
        width: 100%;
        text-align: center;
        left: 0;

        @media screen and (max-width:900px) {
            font-size: 10px;
            top: -1rem;
        }
    }

    button {

        margin-top: 0;
        width: 12rem;
        padding: 0 10px;
        font-size: 20px;
        border-radius: 5px;
        border: 3px solid black;
        background-color: var(--background-color-intense);
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        transition: all 0.2s ease-in-out;
        color: var(--color-text-base);

        &:disabled {
            opacity: 0.8;
            cursor: not-allowed;
        }

        &:not([disabled]) {
            &:hover {
                border-color: var(--color-marker);
                // box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                box-shadow: inset var(--color-marker) 0 0 0px 1px;
            }
        }
    }

    .selectAllfalse {
        width: 5rem;
        margin: 1rem 1rem;
    }

    .selectAlltrue {
        width: 5rem;
        margin: 1rem 1rem;
        color: var(--color-marker);
        border-color: var(--color-marker);
        background-color: var(--background-color-light);
    }

}

.divIsChecked {
    border: 3px solid var(--color-marker);
    transform: scale(1.05);
    z-index: 600;
    background-color: var(--background-color-medium);
}

.listApply {
    background-color: var(--background-color-opacity50);
    width: 100%;
    justify-content: center;
}